import {useStaticQuery, graphql} from "gatsby"

export const GetListEn = () => {
    const {allMarkdownRemark} = useStaticQuery(
        graphql`
        query listJetsEn {
              allMarkdownRemark(filter: {fields: {collection: {eq: "jets_en"}}}) {
                edges {
                  node {
                    frontmatter {
                      slug
                      title
                      pasajeros
                      equipaje
                      thumbnail {
                        childImageSharp {
                          fluid(maxWidth: 300) {
                                ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        `
    )
    return allMarkdownRemark.edges;
}
